.status-list span {
    padding: 0.25em 0.65em;
    border-top: 1px solid #212529;
    border-bottom: 1px solid #212529;
    border-radius: 0;
    color: black;
}
.status-list span:first-child {
    border-left: 1px solid #212529;
    border-right: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.status-list span:last-child {
    border-left: 0;
    border-right: 1px solid #212529;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.danger {
    background-color: #f8d7da;
}
.success {
    background-color: #d1e7dd;
}
.warning {
    background-color: #fff3cd;
}
.primary {
    background-color: #cfe2ff;
}