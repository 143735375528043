:root {
    --otb-success: rgb(75, 179, 75);
    --otb-primary: rgb(0, 119, 255);
    --otb-background: rgb(231, 232, 236);
    --otb-background-secondary: rgb(240, 242, 245);
    --otb-foreground: rgb(255, 255, 255);
    --otb-stoke-accent: #447bba;
    --otb-stroke-danger: rgb(230, 70, 70);
    --otb-background-danger: rgb(250, 235, 235);
    --otb-text-primary: rgb(0, 0, 0);
    --otb-icon-medium: rgb(111, 121, 133);
    --otb-transparent--hover: rgba(0, 16, 61, 0.04);
}
* {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", "Noto Sans Armenian", "Noto Sans Bengali", "Noto Sans Cherokee", "Noto Sans Devanagari", "Noto Sans Ethiopic", "Noto Sans Georgian", "Noto Sans Hebrew", "Noto Sans Kannada", "Noto Sans Khmer", "Noto Sans Lao", "Noto Sans Osmanya", "Noto Sans Tamil", "Noto Sans Telugu", "Noto Sans Thai", sans-serif;
    font-size: 13px;
}
body {
    background-color: var(--otb-background);
}
.hidden {
    display: none;
}
.LoginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 24px 24px;
    background-color: var(--otb-foreground);
    border-radius: 12px;

    width: 288px;
}
.LoginButton {
    background-color: var(--otb-primary);
    border-radius: 8px;
    color: var(--otb-foreground);
    cursor: pointer;
    border: 0;
    font-size: 14px;
    height: 36px;
    width: 240px;
    padding: 0 16px 0 16px;

    margin-top: 20px;
}
.LoginButton:hover:not(.Disabled) {
    opacity: 0.88;
}
.SuccessButton {
    background-color: var(--otb-success);
}
.DangerButton {
    background-color: var(--otb-stroke-danger);
}
.DefaultButton {
    background-color: rgba(235, 242, 250, 0.99);
    color: rgb(55, 112, 177);
}
.Page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.LoginInput {
    height: 36px;
    border-radius: 8px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: var(--otb-background-secondary);
    width: 240px;
    margin-top: 20px;
    padding: 8px 12px 8px 12px;
}
.LoginInput:focus {
    border-color: var(--otb-stoke-accent);
    border-width: 1px;
    outline: 0;
}
.LoginError {
    height: 18px;
    padding: 0 16px 0 16px;
    color: var(--otb-stroke-danger);
}

nav {
    display: flex;
    background-color: var(--otb-foreground);
    height: 48px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 15px;
}

.NavMenu {
    display: flex;
    cursor: pointer;
    color: var(--otb-icon-medium);
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
}

.NavMenu * {
    height: 24px;
    padding: 1px 6px 1px 6px;
    color: var(--otb-icon-medium);
}
.NavMenu:hover, .NavProfile:hover {
    background-color: var(--otb-transparent--hover);
}
.NavProfile {
    display: flex;
    cursor: pointer;
    height: 48px;
    min-width: 48px;
    align-items: center;
    justify-content: center;
}
.NavProfile * {
    margin: 1px 6px 1px 6px;
    /*border-radius: 50%;*/
    height: 24px;
}
.NavProfile:active {
    background-color: rgba(0, 16, 61, 0.08);
}
.ProfileMenu {
    color: black;
    position: absolute;
    background-color: white;
    width: 300px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    right: 15px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 20px 40px 0;
    border-color: rgb(220, 225, 230);
    border-width: 1px;
    padding-bottom: 12px;
    padding-top: 8px;
    z-index: 999;
}
.MenuItem {
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;
    height: 36px;
    line-height: 30px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.MenuItem:hover:not(.disabled) {
    background-color: rgba(174, 183, 194, 0.12);
}
.MenuItem.disabled {
    cursor: not-allowed;
    background-color: rgb(220, 225, 230);
}
.MenuItem.active {
    background-color: rgba(174, 183, 194, 0.12);
}
.AccountBlock {
    margin: 0 12px 6px 12px;
    border-radius: 8px;
    background-color: rgb(240, 242, 245);
    cursor: pointer;
}
.AccountBlock:first-child {
    border-bottom-width: 1px;
    border-bottom-color: rgb(42, 88, 133);
}
.AccountBlock:first-child:hover {
    background-color: rgb(220, 225, 230);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.NavLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    /*height: 24px;*/
    padding: 2px 8px 2px 8px;
    color: rgb(129, 140, 153);
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
}
.NavLink:hover {
    border-radius: 8px;
    border: 1px solid rgba(174, 183, 194, 0.12);
    background-color: rgba(174, 183, 194, 0.12);
}
.NavLink.Active {
    border: 1px solid rgb(220, 225, 230);
    border-radius: 8px;
    color: rgb(55, 112, 177);
    box-shadow: rgba(0, 0, 0, 0.04) 0 4px 8px 0, rgba(0, 0, 0, 0.06) 0 0 4px 0;
}
.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    padding: 8px 12px 8px 12px;
    border-bottom: 1px solid var(--otb-background);
}
.Card .Header {
    display: flex;
    height: 48px;
    line-height: 47px;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 1px solid rgb(237, 238, 240);
    justify-content: center;
    font-weight: bold;
}

.Header .Button {
    border-bottom: 1px solid rgb(237, 238, 240);
    background-color: white;

    display: flex;
    align-items: center;
    color: rgb(129, 140, 153);
    cursor: pointer;
    min-width: 148px;
    font-size: 14px;
    font-weight: normal;
}

.Header .Button:first-child {
    padding-left: 8px;
    border-top-left-radius: 12px;
}
.Header .Button:last-child {
    padding-right: 8px;
    border-top-right-radius: 12px;
}
.Header .Button:first-child:hover {
    background: linear-gradient(to right, rgba(174, 183, 194, 0.12) 50%, transparent);
}
.Header .Button:last-child:hover {
    background: linear-gradient(to left, rgba(174, 183, 194, 0.12) 50%, transparent);
}











.ModalBackground {
    background-color: rgba(0,0,0,0.7);
    /*width: 100vw;*/
    /*min-height: 100%;*/
    overflow-y: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /*align-content: center;*/
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
}
.Modal {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    min-width: 800px;
    /*max-width: 800px;*/
    background-color: rgb(250, 251, 252);
    margin-top: 12px;
}
.ModalHeader {
    display: flex;
    height: 55px;
    background-color: rgb(250, 251, 252);
    border-bottom: 1px solid rgb(220, 225, 230);
    padding: 0 24px 0 24px;
    color: rgb(0, 0, 0);
    font-size: 14px;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.ModalBody {
    display: flex;
    background-color: rgb(255, 255, 255);
    flex-direction: column;
    padding: 20px 24px 20px 24px;
    font-size: 13px;
}
.ModalFooter {
    display: flex;
    height: 55px;
    background-color: rgb(250, 251, 252);
    border-top: 1px solid rgb(220, 225, 230);
    color: rgb(225, 227, 230);
    padding: 0 24px 0 24px;
    align-items: center;
    justify-content: right;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.Disabled {
    opacity: 0.64;
}
.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 32px;
    padding: 0 16px 0 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(53, 108, 172);
}
.Button:hover:not(.Disabled) {
    color: rgb(51, 104, 168);
    background-color: rgb(235, 236, 239);
}
.Button.Default {
    color: rgb(55, 112, 177);
    background-color: rgba(235, 242, 250, 0.99);
}
.Button.Default:hover:not(.Disabled) {
    color: rgb(51, 104, 168);
    background-color: rgba(223, 234, 246, 0.99);
}
.Button.Primary {
    color: rgb(255, 255, 255);
    background-color: rgb(68, 123, 186);
}
.Button.Primary:hover:not(.Disabled) {
    color: rgb(235, 236, 239);
    background-color: rgb(63, 114, 176);
}
.Button.Warning {
    color: #2c2d2e;
    background-color: #ffa000;
}
.Button.Warning:hover:not(.Disabled) {
    color: #2f3034;
    background-color: #eb9405;
}
.Button.Success {
    color: white;
    background-color: rgb(75, 179, 75);
}
.Button.Success:hover:not(.Disabled) {
    color: rgb(235, 236, 239);
    background-color: rgb(69, 166, 74);
}
.Button.Danger {
    color: white;
    background-color: #ff3347;
}
.Button.Danger:hover:not(.Disabled) {
    color: rgb(235, 236, 239);
    background-color: #eb3046;
}
.Disabled {
    cursor: not-allowed;
}
.Loader {
    border-radius: 50%;
    border: 2px solid rgb(220, 225, 230);
    border-bottom: 2px solid transparent;
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.AccordionHeader {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(174, 183, 194, 0.12);
    margin-bottom: 5px;
}
.AccordionHeader:hover {
    background-color: rgba(174, 183, 194, 0.12);
}

.checkBox:before {
    display: inline-block;
    content: ' ';
    border: 2px solid rgb(174, 183, 194);
    border-radius: 5px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 12px;
}
.checkBox.checked:before {
    content: '';
}

input[type='file']::file-selector-button {
    display: none;
}
input.nospin::-webkit-outer-spin-button,
input.nospin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.nospin[type=number] {
    -moz-appearance: textfield;
}
